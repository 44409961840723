import React, { CSSProperties } from 'react';
import Icon from '@common/assets';

import classnames from 'classnames/bind';
import styles from './sliderButton.module.scss';
const cx = classnames.bind(styles);

interface SliderButtonProps {
  visible?: boolean;
  type?: 'prev' | 'next';
  handleClick?: () => void;
  slickClass?: string;
  style?: CSSProperties;
  customClassName?: string;
  // 다크모드 대응(ex. 개발자검사 센터)
  theme?: 'dark' | '';
}

const SliderButton = ({
  visible = true,
  type,
  handleClick,
  slickClass,
  style,
  customClassName,
  theme,
}: SliderButtonProps) => {
  return (
    <a
      className={cx(slickClass, 'btnSlider', type, { hidden: !visible }, customClassName, theme)}
      role="button"
      onClick={handleClick}
      style={{ ...style }}
    >
      {type === 'prev' ? (
        <Icon name="arrowLeftLight" width={32} height={32} />
      ) : (
        <Icon name="arrowRightLight" width={32} height={32} />
      )}
      <span className={cx('hidden')}>{type}</span>
    </a>
  );
};

export const SliderPrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

export const SliderNextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

export default SliderButton;
