import React, { useState } from 'react';
import { Events, Link as ScrollLink } from 'react-scroll';
import { JobPostModalAreaEnum } from '@domain/constant/JobPostModalAreaType';

import classnames from 'classnames/bind';
import styles from '../jobPostModal.module.scss';
const cx = classnames.bind(styles);

interface TabContentsProps {
  scrollMode: string;
  setScrollMode: (scrollMode: string) => void;
}

const TabContents = ({ scrollMode, setScrollMode }: TabContentsProps) => {
  const [activeTab, setActiveTab] = useState<JobPostModalAreaEnum>(JobPostModalAreaEnum.POSITION_LIST);

  const handleTabClick = (tab: JobPostModalAreaEnum) => {
    setScrollMode('CLICK');
    setActiveTab(tab);

    Events.scrollEvent.register('end', () => {
      setScrollMode('SCROLL');
    });
  };

  const handleSetActive = (tab: JobPostModalAreaEnum) => {
    if (scrollMode !== 'SCROLL') return;
    setActiveTab(tab);
  };

  return (
    <div className={cx('tabArea')}>
      <ScrollLink
        to={JobPostModalAreaEnum.POSITION_LIST}
        offset={0}
        smooth={true}
        spy={true}
        className={cx('btnTab', { on: activeTab === JobPostModalAreaEnum.POSITION_LIST })}
        onSetActive={() => handleSetActive(JobPostModalAreaEnum.POSITION_LIST)}
        onClick={() => handleTabClick(JobPostModalAreaEnum.POSITION_LIST)}
        containerId={'jobPostScrollId'}
        role="button"
      >
        모집 포지션
      </ScrollLink>
      <ScrollLink
        to={JobPostModalAreaEnum.JOBPOST_IMAGE_CONTENT}
        offset={0}
        smooth={true}
        spy={true}
        className={cx('btnTab', { on: activeTab === JobPostModalAreaEnum.JOBPOST_IMAGE_CONTENT })}
        onSetActive={() => handleSetActive(JobPostModalAreaEnum.JOBPOST_IMAGE_CONTENT)}
        onClick={() => handleTabClick(JobPostModalAreaEnum.JOBPOST_IMAGE_CONTENT)}
        containerId={'jobPostScrollId'}
        role="button"
      >
        채용 공고
      </ScrollLink>
      <ScrollLink
        to={JobPostModalAreaEnum.SIMILAR_POSITION_LIST}
        offset={0}
        smooth={true}
        spy={true}
        className={cx('btnTab', { on: activeTab === JobPostModalAreaEnum.SIMILAR_POSITION_LIST })}
        onSetActive={() => handleSetActive(JobPostModalAreaEnum.SIMILAR_POSITION_LIST)}
        onClick={() => handleTabClick(JobPostModalAreaEnum.SIMILAR_POSITION_LIST)}
        containerId={'jobPostScrollId'}
        role="button"
      >
        유사 공고
      </ScrollLink>
    </div>
  );
};

export default TabContents;
