import React from 'react';
import EllipsisText from '@common/ellipsisText';
import Image from '@components/common/image';
import { JobPostingDetailVO } from '@domain/vo/job-postings/JobPostingDetailVO';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from '../jobPostModal.module.scss';
const cx = classnames.bind(styles);

interface TagListProps {
  tagListData: string[];
}

const TagList = ({ tagListData }: TagListProps) => {
  const tagListEl = tagListData.map((item, idx) => {
    return (
      <div key={idx} className={cx('tag')}>
        {item}
      </div>
    );
  });

  if (!tagListEl.length) return null;

  return <div className={cx('tagArea')}>{tagListEl}</div>;
};

interface HeaderInfoContentsProps {
  jobPostingDetailData: JobPostingDetailVO;
}

const HeaderInfoContents = ({ jobPostingDetailData }: HeaderInfoContentsProps) => {
  const {
    positionName,
    companyTagList,
    companySn,
    companyName,

    postDateTimeText,
    dDay,
    views,
    representativeImageUrl,
  } = jobPostingDetailData;

  return (
    <div className={cx('jobPostInner')}>
      <div className={cx('jobPostInfoArea')}>
        <EllipsisText text={positionName} type={'height'} offset={64} customClassName={cx('jobPostTitleWrap')} />

        <TagList tagListData={companyTagList} />

        <div className={cx('jobPostInfoInner')}>
          <div className={cx('jobPostInfoWrap')}>
            <div className={cx('jobPostInfoTitle')}>기업</div>
            <a
              href={V2Route.getCompanyChannelPage(companySn)}
              className={cx('jobPostInfoText')}
              target="_blank"
              rel="noreferrer"
            >
              {companyName}
            </a>
          </div>
          <div className={cx('jobPostInfoWrap')}>
            <div className={cx('jobPostInfoTitle')}>기간</div>
            <div className={cx('jobPostInfoText')}>
              {postDateTimeText}
              {dDay && <span className={cx('dDay')}>{dDay}</span>}
            </div>
          </div>
          {/* {views && <div className={cx('viewCount')}>조회수 : {views.toLocaleString()}</div>} */}
        </div>
      </div>

      <div className={cx('jobPostImage')}>
        <Image src={representativeImageUrl} width={320} height={290} alt={`${companyName} 회사`} />
      </div>
    </div>
  );
};

export default HeaderInfoContents;
