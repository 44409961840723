import React, { FC, useEffect, useState } from 'react';
import Modal from '@common/modal';
import Icon from '@common/assets';
import ModalContents from '@common/jobPostModal/modalContents';
import { JobPostModalVO } from '@domain/vo/common/JobPostModalVO';

import { useJobPostingDetail } from '@repository/job-postings/useJobPostings';
import classnames from 'classnames/bind';
import styles from './jobPostModal.module.scss';
const cx = classnames.bind(styles);

interface JobPostModalProps {
  currentKey: string | null; //현재 공고 모달 표시 위치
  jobPostingSn: number | null;
  allJobPostingList: JobPostModalVO[];
  visible: boolean;
  onMoveJobPost: (data: JobPostModalVO) => void;
  onClose: () => void;
  updateCalendarBookmark: React.MutableRefObject<((jobPostingSn: number, newBookmarkStatus: boolean) => void) | null>;
}

const JobPostModal: FC<JobPostModalProps> = ({
  visible,
  onClose,
  onMoveJobPost,
  currentKey,
  jobPostingSn,
  allJobPostingList,
  updateCalendarBookmark,
}) => {
  const [isVisibleLoginModal, setIsVisibleLoginModal] = useState(false);
  const { data: jobPostingDetailData } = useJobPostingDetail(jobPostingSn);
  const currentIdx = allJobPostingList.findIndex((item) => currentKey === item.key);

  const handlePrev = () => {
    //이전 값이 없거나, 현재 데이터를 불러오는 중이라면 실행 x
    if (currentIdx < 1 || !jobPostingDetailData) return;
    onMoveJobPost(allJobPostingList[currentIdx - 1]);
  };

  const handleNext = () => {
    //다음 값이 없거나, 현재 데이터를 불러오는 중이라면 실행 x
    if (currentIdx >= allJobPostingList.length - 1 || !jobPostingDetailData) return;
    onMoveJobPost(allJobPostingList[currentIdx + 1]);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    //로그인 모달 열려있는 경우 동작x
    if (isVisibleLoginModal) return;
    //가로스크롤이 있는 경우(화면 가로가 작아서) 가로스크롤 이동을 막기 위함.
    e.preventDefault();
    switch (e.key) {
      case 'Escape':
        onClose();
        return;
      // TODO bg 슬라이드까지 같이 움직이는 현상이 있어 비활성화 처리 캘린더에서 넣는 방향으로 고려해야함
      case 'ArrowLeft':
        handlePrev();
        return;
      case 'ArrowRight':
        handleNext();
        return;
    }

    return;
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
    //jobPostingDetailDate가 바뀔때마다 handleKeyDown update.
  }, [jobPostingDetailData, isVisibleLoginModal]);

  if (!jobPostingSn) return null;

  const getPrevBtn = () => {
    if (currentIdx < 1) return null;
    const { name } = allJobPostingList[currentIdx - 1];

    return (
      <a className={cx('btnMoveJobPost', 'prev')} role="button" onClick={handlePrev}>
        <div className={cx('icon')}>
          <Icon name="arrowLeftLight" width={32} height={32} />
        </div>
        {name}
      </a>
    );
  };

  const getNextBtn = () => {
    if (currentIdx >= allJobPostingList.length - 1) return null;
    const { name } = allJobPostingList[currentIdx + 1];

    return (
      <a className={cx('btnMoveJobPost', 'next')} role="button" onClick={handleNext}>
        <div className={cx('icon')}>
          <Icon name="arrowRightLight" width={32} height={32} />
        </div>
        {name}
      </a>
    );
  };

  return (
    <Modal visible={visible} dimmed={false}>
      <div className={cx('jobPostModalArea')}>
        {/* 이전 공고 보기 */}
        {getPrevBtn()}

        <ModalContents
          onClose={onClose}
          jobPostingDetailData={jobPostingDetailData}
          updateCalendarBookmark={updateCalendarBookmark}
          isVisibleLoginModal={isVisibleLoginModal}
          setIsVisibleLoginModal={setIsVisibleLoginModal}
        />

        {/* 다음 공고 보기 */}
        {getNextBtn()}
      </div>
    </Modal>
  );
};

export default JobPostModal;
