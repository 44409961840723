import React from 'react';
import { useToast } from '@common/toast';
import Icon from '@components/common/assets';
import { copyString, shareLinkWithFacebook, shareLinkWithKakao } from '@utils/common';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from '../jobPostModal.module.scss';
const cx = classnames.bind(styles);

interface ShareContentsProps {
  jobPostingSn: number;
}

const ShareContents = ({ jobPostingSn }: ShareContentsProps) => {
  const Toast = useToast();

  const handleShareKakao = () => {
    if (!jobPostingSn) return;

    shareLinkWithKakao({
      title: '[잡다] 포지션(공고)을 확인해보세요!',
      description: '잡다에서 포지션 확인하고 지원까지 해보세요!',
      btnText: '자세히 보기',
      imageUrl: 'https://www.jobda.im/img/v2/components/share/imgSharePosition.png',
      mobileWebUrl: `https://m.jobda.im${V2Route.getJobPostingSnModalUrl(jobPostingSn)}`,
      webUrl: `https://www.jobda.im${V2Route.getJobPostingSnModalUrl(jobPostingSn)}`,
    });
  };

  const handleShareFacebook = () => {
    if (!jobPostingSn) return;

    shareLinkWithFacebook(window.location.origin + V2Route.getJobPostingSnModalUrl(jobPostingSn));
  };

  const handleCopy = () => {
    if (!jobPostingSn) return;

    copyString(
      () =>
        Toast({
          iconType: 'info',
          content: 'URL을 복사했습니다.',
        }),
      window.location.origin + V2Route.getJobPostingSnModalUrl(jobPostingSn),
    );
  };

  return (
    <div className={cx('shareArea')}>
      <a className={cx('share')} role="button" onClick={handleShareKakao}>
        <Icon name="shareKakao" width={28} height={28} />
        <span className={cx('hidden')}>kakao talk</span>
      </a>
      <a className={cx('share')} role="button" onClick={handleShareFacebook}>
        <Icon name="shareFacebook" width={28} height={28} />
        <span className={cx('hidden')}>facebook</span>
      </a>
      <a className={cx('share')} role="button" onClick={handleCopy}>
        <Icon name="shareUrl" width={28} height={28} />
        <span className={cx('hidden')}>copy url</span>
      </a>
    </div>
  );
};

export default ShareContents;
