import React, { useRef, useState } from 'react';
import JobPostModal from '@common/jobPostModal/index';
import { JobPostModalVO } from '@domain/vo/common/JobPostModalVO';

export const JobPostModalContext = React.createContext({
  onOpenJobPostModal: (key: string, openJobPostingSn: number, allJobPostingList: JobPostModalVO[] = []) => {
    return;
  }, //jobPostingList가 없을 경우 이전 공고, 다음 공고 화살표 미표시
  onCloseJobPostModal: () => {
    return;
  },
  updateCalendarBookmark: (updateBookmarkStatus: (jobPostingSn: number, newBookmarkStatus: boolean) => void) => {
    return;
  },
});

interface JobPostModalWrapProps {
  children: React.ReactNode;
  beforeClose?: () => void;
}

const JobPostModalWrap = ({ children, beforeClose }: JobPostModalWrapProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState<string | null>(null);
  const [jobPostingSn, setJobPostingSn] = useState<number | null>(null);
  const [allJobPostingList, setAllJobPostingList] = useState<JobPostModalVO[]>([]);
  const updateCalendarBookmarkRef = useRef<((jobPostingSn: number, newBookmarkStatus: boolean) => void) | null>(null);

  const onOpenJobPostModal = (key: string, openJobPostingSn: number, openJobPostingList: JobPostModalVO[] = []) => {
    setCurrentKey(key);
    setModalVisible(true);
    setJobPostingSn(openJobPostingSn);
    setAllJobPostingList(openJobPostingList);
  };

  const onCloseJobPostModal = () => {
    beforeClose?.();
    setCurrentKey(null);
    setModalVisible(false);
    setJobPostingSn(null);
    setAllJobPostingList([]);
  };

  const onMoveJobPost = (data: JobPostModalVO) => {
    setCurrentKey(data.key);
    setJobPostingSn(data.sn);
  };

  const updateCalendarBookmark = (updateBookmarkStatus: (jobPostingSn: number, newBookmarkStatus: boolean) => void) => {
    updateCalendarBookmarkRef.current = updateBookmarkStatus;
  };

  return (
    <JobPostModalContext.Provider value={{ onOpenJobPostModal, onCloseJobPostModal, updateCalendarBookmark }}>
      {children}
      {modalVisible && (
        <JobPostModal
          currentKey={currentKey}
          jobPostingSn={jobPostingSn}
          allJobPostingList={allJobPostingList}
          visible={modalVisible}
          onMoveJobPost={onMoveJobPost}
          onClose={onCloseJobPostModal}
          updateCalendarBookmark={updateCalendarBookmarkRef}
        />
      )}
    </JobPostModalContext.Provider>
  );
};

export default JobPostModalWrap;
