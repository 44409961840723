import { SimilarPositionDto } from '@domain/rs/job-postings/SimilarPositionListRs';

export class SimilarPositionVO {
  sn: number;
  name: string;
  companyName: string;
  postStartDateTime: string;
  postEndDateTime: string;
  logoImageUrl: string;

  constructor(dto: SimilarPositionDto) {
    this.sn = dto.sn;
    this.name = dto.name;
    this.companyName = dto.companyName;
    this.postStartDateTime = dto.postStartDateTime;
    this.postEndDateTime = dto.postEndDateTime;
    this.logoImageUrl = dto.logoImageUrl;
  }
}
