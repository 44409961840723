import React, { FC, useState, useRef, useEffect } from 'react';
import { Element } from 'react-scroll';
import Icon from '@common/assets';
import SimilarJobPostList from '@common/jobPostModal/similarJobPostList';
import CompanySummary from '@common/jobPostModal/companySummary';
import PositionList from '@common/jobPostModal/positionList';
import ShareContents from '@common/jobPostModal/modalContents/ShareContents';
import TabContents from '@common/jobPostModal/modalContents/TabContents';
import HeaderInfoContents from '@common/jobPostModal/modalContents/HeaderInfoContents';
import NoticeBanner from '@common/jobPostModal/noticeBanner';
import LoginModal from '@components/loginModal';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { useAddBookMark, useDeleteBookMark } from '@repository/job-postings/useJobPostings';
import { JobPostingDetailVO } from '@domain/vo/job-postings/JobPostingDetailVO';
import { JobPostModalAreaEnum } from '@domain/constant/JobPostModalAreaType';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from '../jobPostModal.module.scss';
const cx = classnames.bind(styles);

interface JobPostingDataAreaProps {
  jobPostingDetailData: JobPostingDetailVO;
  onClose: () => void;
  updateCalendarBookmark: React.MutableRefObject<((jobPostingSn: number, newBookmarkStatus: boolean) => void) | null>;
  isVisibleLoginModal: boolean;
  setIsVisibleLoginModal: (isVisibleLoginModal: boolean) => void;
}

const JobPostingDataArea: FC<JobPostingDataAreaProps> = ({
  jobPostingDetailData,
  onClose,
  updateCalendarBookmark,
  isVisibleLoginModal,
  setIsVisibleLoginModal,
}) => {
  const { sn, positionName, content, companyInfo, positions, bookmarkYn } = jobPostingDetailData;
  const { data: userData } = useUserInfo();
  const isLogin = !!userData?.id;
  //북마크 버튼
  const [bookmark, setBookmark] = useState(bookmarkYn);
  const { mutateAsync: addBookmark } = useAddBookMark(() => {
    setBookmark(true);
    updateCalendarBookmark.current?.(sn, true);
  });
  const { mutateAsync: deleteBookmark } = useDeleteBookMark(() => {
    setBookmark(false);
    updateCalendarBookmark.current?.(sn, false);
  });

  // 스크롤 판단
  const [isScroll, setIsScroll] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (modalRef.current === null) return;

    if (modalRef.current.scrollTop >= 72) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    handleScroll();

    modalRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      modalRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 탭 클릭인지 스크롤인지 판단
  const [scrollMode, setScrollMode] = useState<string>('SCROLL');

  const handleBookmarkBtn = async () => {
    if (!isLogin) {
      setIsVisibleLoginModal(true);
      return;
    }
    if (bookmark) deleteBookmark(sn);
    else addBookmark(sn);
  };

  return (
    <>
      <div className={cx('layer')}>
        {/* 상단 header 영역 */}
        {/* 페이지 스크롤시 'scroll' 클래스 추가 */}
        <div className={cx('header', { scroll: isScroll })}>
          <strong className={cx('jobPostTitle')}>{positionName}</strong>

          <div className={cx('optionArea')}>
            {/* [D] 북마크 버튼 추가 작업 */}
            <a className={cx('btnBookmark', { on: bookmark })} role="button" onClick={handleBookmarkBtn}>
              {bookmark ? (
                <Icon name="bookmarkLightFilled" width={24} height={24} fill={Colors.C_GREEN_150} />
              ) : (
                <Icon name="bookmarkLight" width={24} height={24} />
              )}
              북마크
            </a>

            <ShareContents jobPostingSn={sn} />

            <a className={cx('btnClose')} role="button" onClick={onClose}>
              <Icon name="closeLight" width={24} height={24} />
              <span className={cx('hidden')}>close</span>
            </a>
          </div>
        </div>

        <div className={cx('scrollArea')}>
          <div className={cx('scrollInner')} id={cx('jobPostScrollId')} ref={modalRef}>
            {/* 공고 영역 */}
            <div className={cx('jobPostArea')}>
              <HeaderInfoContents jobPostingDetailData={jobPostingDetailData} />
            </div>

            {/* 회사 정보 영역 */}
            <div className={cx('companyInfoArea')}>
              {/* 우측 탭 영역 */}
              <TabContents scrollMode={scrollMode} setScrollMode={setScrollMode} />

              {/* 회사 개요 영역 */}
              <CompanySummary companySummaryData={companyInfo} />

              {/* 모집 포지션 영역 */}
              <Element name={JobPostModalAreaEnum.POSITION_LIST}>
                <PositionList
                  jobPostingSn={sn}
                  positionListData={positions}
                  setIsVisibleLoginModal={setIsVisibleLoginModal}
                />
              </Element>

              {/* 공고 이미지 영역 */}
              <Element name={JobPostModalAreaEnum.JOBPOST_IMAGE_CONTENT}>
                <div
                  className={cx('imgJobPostArea')}
                  dangerouslySetInnerHTML={{
                    __html: content ?? '',
                  }}
                />
              </Element>

              {/* 출처 배너 영역 */}
              <div className={cx('noticeBannerArea')}>
                <NoticeBanner />
              </div>

              {/* 비슷한 공고 영역 */}
              <Element name={JobPostModalAreaEnum.SIMILAR_POSITION_LIST}>
                <SimilarJobPostList jobPostingSn={sn} />
              </Element>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        subTitle="채용정보 · 역량검사 · 매칭까지"
        title="취업기회 지금 바로 잡다!"
        visible={isVisibleLoginModal}
        onClose={() => setIsVisibleLoginModal(false)}
      />
    </>
  );
};

interface ModalContentsProps {
  jobPostingDetailData?: JobPostingDetailVO;
  onClose: () => void;
  updateCalendarBookmark: React.MutableRefObject<((jobPostingSn: number, newBookmarkStatus: boolean) => void) | null>;
  isVisibleLoginModal: boolean;
  setIsVisibleLoginModal: (isVisibleLoginModal: boolean) => void;
}

const ModalContents = ({
  onClose,
  jobPostingDetailData,
  updateCalendarBookmark,
  isVisibleLoginModal,
  setIsVisibleLoginModal,
}: ModalContentsProps) => {
  return (
    <div className={cx('jobPostModalInner')}>
      <div className={cx('dimmed')} onClick={onClose} />
      {!!jobPostingDetailData && (
        <JobPostingDataArea
          jobPostingDetailData={jobPostingDetailData}
          onClose={onClose}
          updateCalendarBookmark={updateCalendarBookmark}
          isVisibleLoginModal={isVisibleLoginModal}
          setIsVisibleLoginModal={setIsVisibleLoginModal}
        />
      )}
    </div>
  );
};

export default ModalContents;
