import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import RecommendPositionListRq from '@domain/rq/job-postings/RecommendPositionListRq';
import { SimilarPositionListRs } from '@domain/rs/job-postings/SimilarPositionListRs';
import { JobPostingDetailRs } from '@domain/rs/job-postings/JobPostingDetailRs';
import { CurationPositionListRs } from '@domain/rs/job-postings/CurationPositionListRs';
import { CurationCategoryListRs } from '@domain/rs/job-postings/CurationCategoryListRs';
import { RecommendPositionListRs } from '@domain/rs/job-postings/RecommendPositionListRs';
import { AccPostingPositionListRs } from '@domain/rs/job-postings/AccPostingPositionListRs';
import { AdsTitleRs } from '@domain/rs/job-postings/AdsTitleRs';
import { AdsTitleType } from '@domain/constant/AdsTitleType';

export default interface JobPostingsRepo {
  //공채 모달 - 비슷한 공고 조회 (랜덤)
  fetchJobPostingSimilarPosition(jobPostingSn: number, pageSize: number): Promise<AxiosResponse<SimilarPositionListRs>>;

  //공채 모달 - 채용 공고 상세 조회
  fetchJobPostingDetail(jobPostingSn: number): Promise<AxiosResponse<JobPostingDetailRs>>;

  //큐레이션 공고 조회
  fetchJobPostingCurationPosition(curationCategorySn: number): Promise<AxiosResponse<CurationPositionListRs>>;

  //큐레이션 분류 조회
  fetchJobPostingCurationCategories(): Promise<AxiosResponse<CurationCategoryListRs>>;

  //추천 공고 조회
  fetchJobPostingRecommendPosition(rq: RecommendPositionListRq): Promise<AxiosResponse<RecommendPositionListRs>>;

  //역량검사 활용 공고 목록
  fetchAccPostingPosition(pageSize: number): Promise<AxiosResponse<AccPostingPositionListRs>>;

  //광고 카테고리별 타이틀 조회
  fetchAdsTitle(type: AdsTitleType): Promise<AxiosResponse<AdsTitleRs>>;

  //공고 북마크 추가
  fetchJobpostingBookmark(jobPostingSn: number): Promise<AxiosResponse>;

  //공고 북마크 해제
  deleteJobpostingBookmark(jobPostingSn: number): Promise<AxiosResponse>;
}

export class RemoteJobPostingsRepo implements JobPostingsRepo {
  fetchJobPostingSimilarPosition(jobPostingSn: number, pageSize: number) {
    return axios.get<SimilarPositionListRs>(`/job-postings/${jobPostingSn}/similar`, {
      params: {
        pageSize,
      },
    });
  }

  fetchJobPostingDetail(jobPostingSn: number) {
    return axios.get<JobPostingDetailRs>(`/v2/job-postings/${jobPostingSn}`);
  }

  fetchJobPostingCurationPosition(curationCategorySn: number) {
    return axios.get<CurationPositionListRs>('/job-postings/ads/curation', {
      params: {
        curationCategorySn,
      },
    });
  }

  fetchJobPostingCurationCategories() {
    return axios.get<CurationCategoryListRs>('/job-postings/ads/curation/categories');
  }

  fetchJobPostingRecommendPosition(rq: RecommendPositionListRq) {
    return axios.get<RecommendPositionListRs>('/job-postings/ads/recommend', {
      params: rq,
    });
  }

  fetchAccPostingPosition(pageSize: number) {
    return axios.get<AccPostingPositionListRs>('/job-postings/acc', {
      params: {
        pageSize,
      },
    });
  }

  fetchAdsTitle(type: AdsTitleType) {
    return axios.get('/job-postings/ads/title', {
      params: {
        type,
      },
    });
  }

  fetchJobpostingBookmark(jobPostingSn: number): Promise<AxiosResponse> {
    return axios.put(`/${jobPostingSn}/bookmark`);
  }

  deleteJobpostingBookmark(jobPostingSn: number): Promise<AxiosResponse> {
    return axios.delete(`/${jobPostingSn}/bookmark`);
  }
}
