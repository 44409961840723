import React from 'react';
import Icon from '@common/assets';
import EllipsisText from '@common/ellipsisText';
import Image from '@components/common/image';
import { JobOpeningTextListItemVO } from '@domain/vo/common/JobOpeningTextListItemVO';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './jobOpeningTextListItem.module.scss';
const cx = classnames.bind(styles);

interface JobPostListItemProps {
  positionData: JobOpeningTextListItemVO;
  onClickCard: () => void;
}

const JobOpeningTextListItem = ({ positionData, onClickCard }: JobPostListItemProps) => {
  const { companyName, dDay, closingDateTime, title, logoImageUrl } = positionData;
  const logoImage = logoImageUrl ? `url('${logoImageUrl}')` : '';

  return (
    <a className={cx('btnJobPost')} role="button" onClick={onClickCard}>
      <div className={cx('logo', { default: !logoImage })}>
        {logoImageUrl ? (
          <Image src={logoImageUrl} alt={`${companyName} 로고`} width={48} height={48} />
        ) : (
          <Icon name="logo" width={32} height={7} fill={Colors.C_COOL_GRAY_50} />
        )}
      </div>

      <div className={cx('companyName')}>{companyName}</div>

      <div className={cx('positionCard')}>
        <EllipsisText text={title} type={'width'} offset={234} customClassName={cx('jobPostTitleWrap')} />
      </div>

      <div className={cx('dateArea')}>
        {closingDateTime && <div className={cx('date')}>{closingDateTime}</div>}
        {dDay && <div className={cx('dDay')}>{dDay}</div>}
      </div>
    </a>
  );
};

export default JobOpeningTextListItem;
