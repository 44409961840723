import React, { useState, FC, useContext } from 'react';
import JobOpeningTextListItem from '@common/jobOpeningTextListItem';
import { JobPostModalContext } from '@common/jobPostModal/JobPostModalWrap';
import SliderButton from '@components/common/sliderButton';
import { JobOpeningTextListItemVO } from '@domain/vo/common/JobOpeningTextListItemVO';

import { useJobPostingSimilarPosition } from '@repository/job-postings/useJobPostings';
import classnames from 'classnames/bind';
import styles from './similarJobPostList.module.scss';
const cx = classnames.bind(styles);

interface SimilarJobPostListProps {
  jobPostingSn: number;
}

const SimilarJobPostList: FC<SimilarJobPostListProps> = ({ jobPostingSn }) => {
  const { onOpenJobPostModal } = useContext(JobPostModalContext);
  const { data: positionListData } = useJobPostingSimilarPosition(jobPostingSn, 6);

  const [jobPostItemIndex, setJobPostItemIndex] = useState(0);
  // 마지막 요소의 이동 크기
  const [lastMove, setLastMove] = useState(0);

  const jobPostListItems = positionListData?.map((item) => {
    const positionData = new JobOpeningTextListItemVO({
      ...item,
      title: item.name,
      closingDateTime: item.postEndDateTime,
    });

    return (
      <JobOpeningTextListItem
        key={item.sn}
        positionData={positionData}
        onClickCard={() => onOpenJobPostModal(`${item.sn}`, item.sn)}
      />
    );
  });

  if (!positionListData) return null;

  // 이동되는 크기(카드(282) + 여백(24))
  const moveOffset = 306;
  // 보여지는 카드 갯수
  const jobPostItemViewCount = 2;
  const isPrev = jobPostItemIndex > 0;
  const isNext = jobPostItemIndex < positionListData.length - jobPostItemViewCount;

  const handlePrev = () => {
    if (lastMove !== 0) {
      setLastMove(0);
    }

    setJobPostItemIndex(jobPostItemIndex - 1);
  };

  const handleNext = () => {
    if (jobPostItemIndex === positionListData.length - jobPostItemViewCount - 1) {
      // 이동되는 크기(306) - 마지막 요소 안보이는 부분(70)
      setLastMove(236);
    }

    setJobPostItemIndex(jobPostItemIndex + 1);
  };

  return (
    <div className={cx('similarJobPostListArea')}>
      <div className={cx('sectionTitleArea')}>
        <em className={cx('sectionTitle')}>지금 보고 있는 공고와 비슷한 공고</em>
      </div>
      <div className={cx('similarJobPostListInner')}>
        <SliderButton visible={isPrev} type={'prev'} handleClick={handlePrev} />

        <div className={cx('similarJobPostListWrap')}>
          <div
            className={cx('similarJobPostList')}
            style={{ transform: `translateX(${-jobPostItemIndex * moveOffset + lastMove}px)` }}
          >
            {jobPostListItems}
          </div>
        </div>

        <SliderButton visible={isNext} type={'next'} handleClick={handleNext} />
      </div>
    </div>
  );
};

export default SimilarJobPostList;
