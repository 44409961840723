import { CurationPositionDto } from '@domain/rs/job-postings/CurationPositionListRs';
import DateFormat from '@utils/date/format';

export default class CurationPositionVO {
  jobPostingSn: number;
  companyName: string;
  positionName: string;

  postStartDateTime: string;
  postEndDateTime: string;
  dDay: string | null;

  companyLogoUrl: string;
  representativeImageUrl: string;

  constructor(dto: CurationPositionDto) {
    this.jobPostingSn = dto.jobPostingSn;

    this.companyName = dto.companyName;
    this.positionName = dto.jobPostingName;

    this.postStartDateTime = dto.postStartDateTime;
    this.postEndDateTime = dto.postEndDateTime
      ? DateFormat.format(new Date(dto.postEndDateTime), 'yyyy.MM.dd(week) HH:mm')
      : '';
    this.dDay = DateFormat.formatPositionDDayText(dto.postEndDateTime ? new Date(dto.postEndDateTime) : null);

    this.companyLogoUrl = dto.companyLogoUrl;
    this.representativeImageUrl = dto.representativeImageUrl;
  }
}
