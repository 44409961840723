import {
  CompanyDetailDto,
  ExternalInfoDto,
  JobPostingDetailDto,
  JobPostingPositionDto,
} from '@domain/rs/job-postings/JobPostingDetailRs';
import { ScreeningType } from '@domain/constant/ScreeningType';
import { RecruitCategoryTypeNameMap, RecruitCategoryType } from '@domain/constant/recruitCategoryType';
import { PositionApplyType } from '@domain/constant/PositionApplyType';
import { businessSizeTextMap, BusinessSizeType } from '@domain/constant/businessSize';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';

//외부 포지션 정보
export class ExternalInfoVO {
  url: string | null;
  startDateTime: string;

  constructor(dto: ExternalInfoDto) {
    this.url = dto.url;
    this.startDateTime = dto.startDateTime;
  }
}

export class JobPostingPositionVO {
  positionSn: number;
  positionName: string;
  priority: number;
  recruitmentType: RecruitCategoryType;
  recruitmentTypeText: string;
  locationCode: number;
  locationName: string;

  applyType: PositionApplyType;
  externalInfo: ExternalInfoVO | null;
  recruitmentUrl: string;

  constructor(dto: JobPostingPositionDto) {
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionName;
    this.priority = dto.priority;
    this.recruitmentType = dto.recruitmentType;
    this.recruitmentTypeText = RecruitCategoryTypeNameMap[dto.recruitmentType];
    this.locationCode = dto.locationCode;
    this.locationName = NumberFormat.formatLocationName(dto.locationCode);
    this.applyType = dto.applyType;
    this.externalInfo = dto.externalInfo ? new ExternalInfoVO(dto.externalInfo) : null;
    this.recruitmentUrl = dto.recruitmentUrl;
  }
}

export class CompanyDetailVO {
  ceoName: string; // 대표자명
  startingSalary: number; // 올해 입사자 연봉
  startingSalaryText: string;
  averageSalary: number; // 전체 종업원 평균 연봉
  averageSalaryText: string;

  numberOfEmployees: number; // 전체 직원 수
  numberOfEmployeesText: string;

  businessSize: BusinessSizeType; // 기업 규모
  businessSizeText: string;
  revenue: number; // 매출액
  revenueText: string;
  profit: number; // 영업 이익
  profitText: string;
  establishDate: string; // 설립일
  location: string | null; // 주소
  lastModifiedDateText: string;

  constructor(rs: CompanyDetailDto) {
    this.ceoName = rs.ceoName ? rs.ceoName : '-';
    this.startingSalary = rs.startingSalary;
    this.startingSalaryText = this.getSalaryText(rs.startingSalary, 10000);
    this.averageSalary = rs.startingSalary;
    this.averageSalaryText = this.getSalaryText(rs.averageSalary, 10000);
    this.numberOfEmployees = rs.numberOfEmployees;
    this.numberOfEmployeesText = rs.numberOfEmployees ? `${NumberFormat.formatDecimal(rs.numberOfEmployees)}명` : '-';
    this.businessSize = rs.businessSize;
    this.businessSizeText = rs.businessSize ? businessSizeTextMap[rs.businessSize] : '-';
    this.revenue = rs.revenue;
    this.revenueText = this.getSalaryText(rs.revenue, 10000 * 10000);
    this.profit = rs.profit;
    this.profitText = this.getSalaryText(rs.profit, 10000 * 10000);
    this.establishDate = rs.establishDate ? rs.establishDate : '-';
    this.location = rs.location ? rs.location : null;
    this.lastModifiedDateText = this.getDateFormat(rs.lastModifiedDate);
  }

  getSalaryText(salary: number | null, unit = 1) {
    if (salary === null || typeof salary !== 'number') return '-';
    if (salary < unit) return '-';
    const parserSalary = Math.trunc(salary / unit) * unit; //unit 만큼 0으로 채움
    return NumberFormat.formatDecimal(NumberFormat.format(parserSalary, 'ALL', ' '));
  }

  getDateFormat(data: string | null) {
    return data ? `업데이트 일자: ${DateFormat.format(new Date(data), 'yyyy.MM.dd')}` : '';
  }
}

export class JobPostingDetailVO {
  sn: number;

  positionName: string;

  companyName: string;
  companyInfo: CompanyDetailVO;
  companyTagList: string[];

  postStartDateTime: string;
  postEndDateTime: string;
  postDateTimeText: string; // 공고 기간
  dDay: string | null;

  screeningType: ScreeningType;
  openYn: boolean;
  companySn: number;
  positions: JobPostingPositionVO[];
  companyLogoUrl: string;
  representativeImageUrl: string;
  content: string;
  views: number;
  bookmarkYn: boolean;

  constructor(dto: JobPostingDetailDto) {
    this.sn = dto.sn;

    this.positionName = dto.name;

    this.companyName = dto.companyName;
    this.companyInfo = new CompanyDetailVO(dto.companyInfo);
    this.postStartDateTime = dto.postStartDateTime;
    this.postEndDateTime = dto.postEndDateTime;

    this.postDateTimeText = this.getPostDateTimeText(dto.postStartDateTime, dto.postEndDateTime);
    this.dDay = dto.postEndDateTime ? DateFormat.formatPositionDDayText(new Date(dto.postEndDateTime)) : '';

    this.screeningType = dto.screeningType;
    this.openYn = dto.openYn;
    this.companySn = dto.companySn;
    this.positions = dto.positions.map((item) => new JobPostingPositionVO(item));
    this.companyLogoUrl = dto.companyLogoUrl;
    this.representativeImageUrl = dto.representativeImageUrl;
    this.content = dto.content;
    this.views = dto.views;

    //기업 정보 태그 리스트
    this.companyTagList = this.getCompanyTagList(this.positions);
    this.bookmarkYn = dto.bookmarkYn;
  }

  getCompanyTagList(list: JobPostingPositionVO[]) {
    const recruitTagList: string[] = [];
    const locationTagList: string[] = [];

    list?.forEach((item) => {
      if (item.recruitmentTypeText) recruitTagList.push(`#${item.recruitmentTypeText}`);
      if (item.locationName) locationTagList.push(`#${item.locationName}`);
    });

    return Array.from(new Set([...recruitTagList, ...locationTagList]));
  }

  getPostDateTimeText(startDate: string, endDate: string) {
    let result = '';

    if (!endDate) return '상시채용';
    if (startDate) result += DateFormat.format(new Date(startDate), 'yyyy.MM.dd(week) HH:mm');
    if (endDate) result += ` ~ ${DateFormat.format(new Date(endDate), 'yyyy.MM.dd(week) HH:mm')}`;

    return result;
  }
}
