import { useMutation, useQuery } from 'react-query';
import { RemoteJobPostingsRepo } from './jobPostingsRepo';
import RecommendPositionListRq from '@domain/rq/job-postings/RecommendPositionListRq';
import { SimilarPositionVO } from '@domain/vo/job-postings/SimilarPositionVO';
import { JobPostingDetailVO } from '@domain/vo/job-postings/JobPostingDetailVO';
import CurationPositionVO from '@domain/vo/job-postings/CurationPositionVO';
import CurationCategoryVO from '@domain/vo/job-postings/CurationCategoryVO';
import { AdsTitleType } from '@domain/constant/AdsTitleType';

const repo = new RemoteJobPostingsRepo();

export const keys = ['job-postings'];

//공채 모달 - 비슷한 공고 조회 (랜덤)
const fetchJobPostingSimilarPosition = async (jobPostingSn: number, pageSize = 6) => {
  const { data } = await repo.fetchJobPostingSimilarPosition(jobPostingSn, pageSize);
  return data.jobPostings.map((item) => new SimilarPositionVO(item));
};

//공채 모달 - 채용 공고 상세 조회
const fetchJobPostingDetail = async (jobPostingSn: number | null) => {
  if (!jobPostingSn) return;

  const { data } = await repo.fetchJobPostingDetail(jobPostingSn);
  return new JobPostingDetailVO(data);
};

//큐레이션 공고 조회
export const fetchJobPostingCurationPosition = async (curationCategorySn?: number) => {
  if (!curationCategorySn) return [];

  const { data } = await repo.fetchJobPostingCurationPosition(curationCategorySn);
  return data.jobPostings.map((item) => new CurationPositionVO(item));
};

//큐레이션 분류 조회
export const fetchJobPostingCurationCategories = async () => {
  const { data } = await repo.fetchJobPostingCurationCategories();
  return data.categories.map((item) => new CurationCategoryVO(item));
};

//추천 공고 조회
export const fetchJobPostingRecommendPosition = async (rq: RecommendPositionListRq) => {
  const { data } = await repo.fetchJobPostingRecommendPosition(rq);

  // TODO view에서 랜덤 처리 필요
  const length = data?.jobPostings?.length || 0;
  const randomSortList = data.jobPostings.slice(0, length).sort(() => Math.random() - 0.5);
  const remainList = data.jobPostings.slice(length);

  return [...randomSortList, ...remainList];
};

//역량검사 활용 공고 목록
export const fetchAccPostingPosition = async (pageSize: number) => {
  const { data } = await repo.fetchAccPostingPosition(pageSize);
  return data;
};

//광고 카테고리별 타이틀 조회
export const fetchAdsTitle = async (type: AdsTitleType) => {
  const { data } = await repo.fetchAdsTitle(type);
  return data;
};

//공고 북마크 추가
const fetchJobpostingBookmark = async (jobPostingSn: number) => {
  return await repo.fetchJobpostingBookmark(jobPostingSn);
};

//공고 북마크 해제
const deleteJobpostingBookmark = async (jobPostingSn: number) => {
  return await repo.deleteJobpostingBookmark(jobPostingSn);
};

export function useJobPostingSimilarPosition(jobGroupCode: number, pageSize = 6) {
  return useQuery([...keys, 'jobPosting', 'PositionList', 'similar', jobGroupCode, pageSize], () =>
    fetchJobPostingSimilarPosition(jobGroupCode, pageSize),
  );
}

export function useJobPostingDetail(jobGroupCode: number | null) {
  return useQuery([...keys, 'jobPosting', 'detail', jobGroupCode], () => fetchJobPostingDetail(jobGroupCode), {
    enabled: !!jobGroupCode,
    cacheTime: 0, //최신 데이터를 보여주기 위함.
  });
}

export function useJobPostingCurationPosition(curationCategorySn?: number) {
  return useQuery(
    [...keys, 'PositionList', 'Curation', curationCategorySn],
    () => fetchJobPostingCurationPosition(curationCategorySn),
    {
      enabled: !!curationCategorySn,
    },
  );
}

export function useJobPostingCurationCategories() {
  return useQuery([...keys, 'categoryList', 'Curation'], fetchJobPostingCurationCategories);
}

export function useJobPostingRecommendPosition(rq: RecommendPositionListRq) {
  return useQuery([...keys, 'PositionList', 'recommend', rq], () => fetchJobPostingRecommendPosition(rq));
}

export function useAccPostingPosition(pageSize: number) {
  return useQuery([...keys, 'acc', 'PositionList', pageSize], () => fetchAccPostingPosition(pageSize));
}

export function useAdsTitle(type: AdsTitleType) {
  return useQuery([...keys, 'ads', 'title', type], () => fetchAdsTitle(type));
}

export function useAddBookMark(onSuccess: () => void) {
  return useMutation((jobPostingSn: number) => fetchJobpostingBookmark(jobPostingSn), {
    onSuccess,
  });
}

export function useDeleteBookMark(onSuccess: () => void) {
  return useMutation((jobPostingSn: number) => deleteJobpostingBookmark(jobPostingSn), {
    onSuccess,
  });
}
