import React, { useState, FC } from 'react';
import Icon from '@common/assets';
import Colors from '@domain/constant/colors';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';

import classnames from 'classnames/bind';
import styles from './noticeBanner.module.scss';
const cx = classnames.bind(styles);

interface CmsNoticeBannerProps {}

const NoticeBanner: FC<CmsNoticeBannerProps> = () => {
  const [additionalDescVisible, setAdditionalDescVisible] = useState(false);

  const handelClickDescArea = () => {
    setAdditionalDescVisible(!additionalDescVisible);
  };

  return (
    <div className={cx('noticeBannerWrap')}>
      <div className={cx('infoArea')}>
        <div className={cx('infoInner')}>
          <Icon name="logo" width={80} height={18} />

          <div className={cx('descArea')}>
            <Icon name="informationLight" width={20} height={20} fill={Colors.C_COOL_GRAY_60} />

            <div className={cx('descInner')}>
              <p className={cx('desc')}>기재된 채용정보는 구직활동 이외의 용도로 사용하실 수 없습니다.</p>

              <a className={cx('btnArrow')} role="button" onClick={handelClickDescArea}>
                {additionalDescVisible ? (
                  <Icon name="arrowTopLight" width={24} height={24} />
                ) : (
                  <Icon name="arrowBottomLight" width={24} height={24} />
                )}
              </a>
            </div>
          </div>
        </div>

        <a href={`mailto:${JOBDA_SUPPORT_EMAIL}`} className={cx('linkArea')} target="_blank" rel="noreferrer">
          <div className={cx('linkText')}>채용정보 수정 요청</div>
          <Icon name="arrowRightLight" width={24} height={24} fill={Colors.C_JOBDA_BLACK} />
        </a>
      </div>

      {additionalDescVisible && (
        <div className={cx('additionalInfoArea')}>
          <p className={cx('desc')}>
            본 채용의 모집 분야, 모집 기간 등의 채용 정보는 기업의 실제 채용정보와 상이할 수 있습니다.
            <br />
            기업에서 제공하는 정보와 상이한 내용에 대해 자인원에서는 어떠한 보장도 하지 않으며, 이에 대한 책임을 지지
            않습니다.
          </p>
        </div>
      )}
    </div>
  );
};

export default NoticeBanner;
