import React, { useState } from 'react';
import Icon from '@common/assets';
import EllipsisText from '@common/ellipsisText';
import SliderButton from '@components/common/sliderButton';

import { CompanyDetailVO } from '@domain/vo/job-postings/JobPostingDetailVO';
import classnames from 'classnames/bind';
import styles from './companySummary.module.scss';
const cx = classnames.bind(styles);

type companySummaryItemType =
  | 'CEO_NAME'
  | 'STARTING_SALARY_TEXT'
  | 'NUMBER_OF_EMPLOYEES'
  | 'BUSINESS_SIZE'
  | 'REVENUE'
  | 'PROFIT'
  | 'ESTABLISH_DATE';

interface CompanySummaryItemProps {
  title: string;
  type: companySummaryItemType;
}

const CompanySummaryItem = ({ title, type }: CompanySummaryItemProps) => {
  const getIconText = (type: string) => {
    switch (type) {
      case 'CEO_NAME':
        return (
          <>
            <Icon name="summaryStar" width={40} height={40} />
            <div className={cx('text')}>대표자명</div>
          </>
        );
      case 'STARTING_SALARY_TEXT':
        return (
          <>
            <Icon name="summarySalary" width={40} height={40} />
            <div className={cx('text')}>전체 종업원 평균 연봉</div>
          </>
        );
      case 'NUMBER_OF_EMPLOYEES':
        return (
          <>
            <Icon name="summaryPeople" width={40} height={40} />
            <div className={cx('text')}>직원수</div>
          </>
        );
      case 'BUSINESS_SIZE':
        return (
          <>
            <Icon name="summaryBuilding" width={40} height={40} />
            <div className={cx('text')}>기업 구분</div>
          </>
        );
      case 'REVENUE':
        return (
          <>
            <Icon name="summaryPresentation" width={40} height={40} />
            <div className={cx('text')}>매출액</div>
          </>
        );
      case 'PROFIT':
        return (
          <>
            <Icon name="summaryGraphBar" width={40} height={40} />
            <div className={cx('text')}>영업 이익</div>
          </>
        );
      case 'ESTABLISH_DATE':
        return (
          <>
            <Icon name="summaryFlag" width={40} height={40} />
            <div className={cx('text')}>설립일</div>
          </>
        );
    }
  };

  return (
    <div className={cx('listItem')}>
      {getIconText(type)}

      <EllipsisText text={title} type={'width'} offset={104} customClassName={cx('titleWrap')} />
    </div>
  );
};

interface CompanySummaryProps {
  companySummaryData: CompanyDetailVO;
}

const CompanySummary = ({ companySummaryData }: CompanySummaryProps) => {
  const {
    ceoName,
    averageSalaryText,
    numberOfEmployeesText,
    businessSizeText,
    revenueText,
    profitText,
    establishDate,
    lastModifiedDateText,
  } = companySummaryData;

  const [summaryItemIndex, setSummaryItemIndex] = useState(0);
  // 한번에 영역 끝까지 이동하는 모션
  // 이동되는 크기 = (카드(104) + 여백(61)) * 움직이는 카드 갯수(2) - 마지막 요소 안보이는 부분(12)
  const moveOffset = 318;
  const isPrev = summaryItemIndex > 0;
  const isNext = summaryItemIndex < 1;

  const handlePrev = () => {
    setSummaryItemIndex(summaryItemIndex - 1);
  };

  const handleNext = () => {
    setSummaryItemIndex(summaryItemIndex + 1);
  };

  return (
    <div className={cx('companySummaryArea')}>
      <div className={cx('sectionTitleArea')}>
        <em className={cx('sectionTitle')}>회사 개요</em>
        <span className={cx('sectionText')}>{lastModifiedDateText}</span>
      </div>
      <div className={cx('companyInfoListArea')}>
        <SliderButton visible={isPrev} type={'prev'} handleClick={handlePrev} />

        <div className={cx('companyInfoListWrap')}>
          <div
            className={cx('companyInfoList')}
            style={{ transform: `translateX(${-summaryItemIndex * moveOffset}px)` }}
          >
            <CompanySummaryItem title={ceoName} type={'CEO_NAME'} />
            <CompanySummaryItem title={averageSalaryText} type={'STARTING_SALARY_TEXT'} />
            <CompanySummaryItem title={numberOfEmployeesText} type={'NUMBER_OF_EMPLOYEES'} />
            <CompanySummaryItem title={businessSizeText} type={'BUSINESS_SIZE'} />
            <CompanySummaryItem title={revenueText} type={'REVENUE'} />
            <CompanySummaryItem title={profitText} type={'PROFIT'} />
            <CompanySummaryItem title={establishDate} type={'ESTABLISH_DATE'} />
          </div>
        </div>

        <SliderButton visible={isNext} type={'next'} handleClick={handleNext} />
      </div>
    </div>
  );
};

export default CompanySummary;
