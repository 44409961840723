import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import EllipsisText from '@common/ellipsisText';
import { LogTypeEnum } from '@/src/logging/LogType';
import { JobPostingPositionVO } from '@domain/vo/job-postings/JobPostingDetailVO';
import Colors from '@domain/constant/colors';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './positionList.module.scss';
const cx = classnames.bind(styles);

interface PositionCardProps {
  positionData: JobPostingPositionVO;
  onOpenLoginModal: () => void;
}

const PositionCard = ({ positionData, onOpenLoginModal }: PositionCardProps) => {
  const { positionSn, positionName, recruitmentTypeText, locationName } = positionData;

  return (
    <div className={cx('listItem')}>
      <Link
        className={cx('link')}
        href={V2Route.getPositionJDPage(positionSn)}
        target="_blank"
        id={LogTypeEnum.log_job_posting_modal_position}
      >
        <>
          <div className={cx('positionInfoArea')}>
            <div className={cx('recruitmentType')}>{recruitmentTypeText}</div>

            <div className={cx('positionTitleArea')}>
              <EllipsisText text={positionName} type={'width'} offset={518} customClassName={cx('positionTitleWrap')} />
            </div>
          </div>

          <div className={cx('locationArea')}>
            {locationName && (
              <div className={cx('location')}>
                <Icon name="pinLight" width={24} height={24} fill={Colors.C_COOL_GRAY_60} />
                {locationName}
              </div>
            )}
            <Icon name="arrowRightLight" width={32} height={32} />
          </div>
        </>
      </Link>
    </div>
  );
};

interface PositionListProps {
  jobPostingSn: number;
  positionListData: JobPostingPositionVO[];
  setIsVisibleLoginModal: (isVisibleLoginModal: boolean) => void;
}

const PositionList = ({ jobPostingSn, positionListData, setIsVisibleLoginModal }: PositionListProps) => {
  const [moreBtnVisible, setMoreBtnVisible] = useState(false);
  const positionList = moreBtnVisible ? positionListData.slice(0, 5) : positionListData;

  useEffect(() => {
    if (jobPostingSn) {
      setMoreBtnVisible(positionListData.length > 5);
    }
  }, [jobPostingSn]);
  if (!positionListData.length) return null;

  const handleOpenLoginModal = () => {
    setIsVisibleLoginModal(true);
  };

  const positionListEl = positionList.map((item, index) => {
    return <PositionCard key={item.positionSn} positionData={item} onOpenLoginModal={handleOpenLoginModal} />;
  });

  const handleClickMoreBtn = () => {
    setMoreBtnVisible(false);
  };

  return (
    <div className={cx('positionListArea')}>
      <div className={cx('sectionTitleArea')}>
        <em className={cx('sectionTitle')}>모집 포지션</em>
      </div>
      <div className={cx('positionList')}>{positionListEl}</div>
      {moreBtnVisible && (
        <a className={cx('btnMore')} role="button" onClick={handleClickMoreBtn}>
          더보기
          <Icon name="arrowBottomLight" width={32} height={32} />
        </a>
      )}
    </div>
  );
};

export default PositionList;
